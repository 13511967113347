import { Icon, translate as _, ajx, deepCopy, parseJSON } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, FormCheck, Modal } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import Documents from '../elements/Documents'
import { createOptions, deleteItem, getBooleanValue, getDateValue, getGenders, getSelectValue, getStringValue, updateItem } from '../../util'
import { getFieldDefinition } from '../../fieldDefinition'
import { PERSON_REQUEST_STATUS_ACCEPTED, PERSON_REQUEST_STATUS_DRAFT, PERSON_REQUEST_STATUS_PENDING, PERSON_REQUEST_STATUS_REJECTED } from '../../util/constants'
import { datatron } from '../../util/datatron'
import { documentType } from '../../objectStores/documentType'

export default class PersonRequest extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            documentTypes:   null,
            error:           false,
            fieldDefinition: null,
            isNew:           false,
            keepDocuments:   true,
            personRequest:   null,
            responseStatus:  null,
            saving:          false,
            show:            false,
            step:            1,
            success:         false,
            validated:       false,
            valueOptions:    { genders: getGenders() },

        }

        this.#dom = {

            form: React.createRef(),

        }

    }

    componentDidMount() {

        this.load()

        this.loadFieldDefinition()

        this.loadDocumentTypes()

    }

    componentDidUpdate() {

        if ( this.props.personRequest && ! this.state.personRequest ) {

            this.setState( {

                error:         false,
                isNew:         this.props.personRequest.isNew,
                keepDocuments: true,
                personRequest: this.convertPersonRequest( this.props.personRequest ),
                saving:        false,
                step:          1,
                success:       false,
                validated:     false,

            } )

        } else if ( ! this.props.personRequest && this.state.personRequest ) {

            this.setState( { personRequest: null } )

        }

    }

    canEdit() {

        if ( this.state.isNew ) {

            return true

        }

        if ( this.state.personRequest.Status === PERSON_REQUEST_STATUS_PENDING && this.props.respondable ) {

            return true

        }

        return false

    }

    convertPersonRequest( personRequest ) {

        personRequest = deepCopy( personRequest )

        personRequest.Gender           = personRequest.Gender           !== null                                         ? getGenders().find(                         e => e.value === personRequest.Gender           ) : null
        personRequest.TargetLeagueType = personRequest.TargetLeagueType !== null && this.state.valueOptions.leaguesTypes ? this.state.valueOptions.leaguesTypes.find( e => e.value === personRequest.TargetLeagueType ) : null

        return personRequest

    }

    getStatusMessage( text, className, icon, spin ) {

        const iconProps = { icon }

        if ( spin ) {

            iconProps.spin = true

        }

        return (

            <div className={ 'text-center ' + className }>

                <div className='fs-1 my-3'>

                    <Icon { ...iconProps } />

                </div>

                <p className='lead'>{ text }</p>

            </div>

        )

    }

    load() {

        ajx( {

            options: { method: 'GET' },
            success: e => this.setState( { valueOptions: { ...this.state.valueOptions, leaguesTypes: createOptions( e ) } } ),
            url:     'api/LeagueType/Get'

        } )

    }

    loadDocumentTypes() {

        datatron.get( {

            cacheFirst:     true,
            objectStore:    documentType,
            requestOptions: { options: { method: 'GET' }, url: 'api/DocumentType/Get' },
            success:        documentTypes => this.setState( { documentTypes: documentTypes.data.filter( e => e.object === 'PersonRequest' ) } ),

        } )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'PersonRequest',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    respond( status ) {

        this.setState( { error: false, responseStatus: status, saving: true, success: false }, () => {

            const data = {

                birthDate:     this.state.personRequest.Birthdate,
                birthName:     getStringValue( this.state.personRequest.BirthName ),
                firstName:     getStringValue( this.state.personRequest.FirstName ),
                gender:        getSelectValue( this.state.personRequest.Gender    ),
                id:            this.state.personRequest.Id,
                keepDocuments: getBooleanValue( this.state.keepDocuments          ),
                lastName:      getStringValue( this.state.personRequest.LastName  ),

                status,

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                error:    () => this.setState( { error: true } ),
                single:   true,
                success:  personRequest => this.setState( { personRequest, success: true }, () => this.props.onUpdate( personRequest ) ),
                url:      'api/PersonRequest/Respond',

                data,

            } )

        } )

    }

    save( status, step ) {

        if ( this.#dom.form.current && ! this.#dom.form.current.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { error: false, saving: true, success: false, validated: true }, () => {

            const data = {

                birthDate:    getDateValue( this.state.personRequest.Birthdate                            ),
                birthName:    getStringValue( this.state.personRequest.BirthName                          ),
                clubId:       this.props.context && this.props.context.club && this.props.context.club.id,
                firstName:    getStringValue( this.state.personRequest.FirstName                          ),
                gender:       getSelectValue( this.state.personRequest.Gender                             ),
                lastName:     getStringValue( this.state.personRequest.LastName                           ),
                leagueTypeId: getSelectValue( this.state.personRequest.TargetLeagueType                   ),

                status,

            }

            if ( this.state.personRequest.Id ) {

                data.id = this.state.personRequest.Id

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                error:    () => this.setState( { error: true } ),
                single:   true,
                url:      'api/PersonRequest/AddOrEdit',

                success:  personRequest => {

                    personRequest = this.convertPersonRequest( personRequest )

                    this.setState( { personRequest, step, success: true }, () => step === 3 && this.props.onUpdate( personRequest ) )

                },

                data,

            } )

        } )

    }

    send() {

        this.setState( { step: 3 }, () => this.save( PERSON_REQUEST_STATUS_PENDING, 3 ) )

    }

    renderBody() {

        return (

            this.state.saving ?

                this.getStatusMessage( this.state.responseStatus === PERSON_REQUEST_STATUS_ACCEPTED ? _( 'Antrag wird akzeptiert...' ) : _( 'Antrag wird abgelehnt...' ), '', 'spinner', true )

            : this.state.success ?

                this.getStatusMessage( this.state.responseStatus === PERSON_REQUEST_STATUS_ACCEPTED ? _( 'Antrag wurde akzeptiert.' ) : _( 'Antrag wurde abgelehnt.' ), 'text-success', 'check' )

            : this.state.error ?

                this.getStatusMessage( this.state.responseStatus === PERSON_REQUEST_STATUS_ACCEPTED ? _( 'Antrag konnte nicht akzeptiert werden.' ) : _( 'Antrag konnte nicht abgelehnt werden.' ), 'text-danger', 'times' )

            :

                <>

                    { this.renderFields() }

                    <h6 className='my-3 pt-3 border-top text-muted'>{ _( 'Dokumente' ) }</h6>

                    { this.renderDocuments() }

                    { this.state.personRequest.Status === PERSON_REQUEST_STATUS_PENDING && this.props.respondable &&

                        <FormCheck
                            checked={ this.state.keepDocuments }
                            disabled={ this.state.saving }
                            id='keepDocuments'
                            label={ _( 'Dokumente übernehmen' ) }
                            onChange={ e => this.setState( { keepDocuments: e.target.checked } ) }
                        />

                    }

                </>

        )

    }

    renderButtons() {

        return (

            <>

                { this.state.personRequest.Status === PERSON_REQUEST_STATUS_PENDING && this.props.respondable ?

                    <>

                        <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                        <Button onClick={ () => this.respond( PERSON_REQUEST_STATUS_ACCEPTED ) } disabled={ this.state.saving }>{ _( 'Akzeptieren' ) }</Button>

                        <Button onClick={ () => this.respond( PERSON_REQUEST_STATUS_REJECTED ) } disabled={ this.state.saving } variant='outline-danger'>{ _( 'Ablehnen' ) }</Button>

                    </>

                :

                    <>

                        <Button onClick={ this.props.onHide }>{ _( 'Schließen' ) }</Button>

                    </>

                }

            </>

        )

    }

    renderDocuments() {

        return (

            <>

                <Documents
                    disabled=     { ! this.canEdit() || this.state.saving }
                    documents=    { this.state.personRequest.PersonDocuments }
                    documentTypes={ this.state.documentTypes }
                    objectName=   'PersonRequest'
                    onChange=     { e => this.setState( { personRequest: { ...this.state.personRequest, PersonDocuments: updateItem( this.state.personRequest.PersonDocuments, e ) } } ) }
                    onDelete=     { e => this.setState( { personRequest: { ...this.state.personRequest, PersonDocuments: deleteItem( this.state.personRequest.PersonDocuments, e ) } } ) }
                    requiredKey=  'requiredForPersonRequest'
                    uploadParams= { { personRequestId: this.state.personRequest.Id } }
                />

            </>

        )

    }

    renderFields() {

        return (

            <Form onSubmit={ e => e.preventDefault() } noValidate validated={ this.state.validated } ref={ this.#dom.form }>

                <FieldSet
                    disabled={ ! this.canEdit() || this.state.saving }
                    fieldDefinition={ this.state.fieldDefinition }
                    onChange={ ( e, f ) => this.setState( { personRequest: { ...this.state.personRequest, [ e ]: f } } ) }
                    valueOptions={ this.state.valueOptions }
                    values={ this.state.personRequest }
                />

            </Form>

        )

    }

    renderRequestBody() {

        return (

            this.state.step === 1 ?

                this.renderFields()

            : this.state.step === 2 ?

                this.renderDocuments()

            :

                ( this.state.saving ?

                    this.getStatusMessage( _( 'Antrag wird gesendet...' ), '', 'spinner', true )

                : this.state.success ?

                    this.getStatusMessage( _( 'Antrag wurde gesendet.' ), 'text-success', 'check' )

                : this.state.error ?

                    this.getStatusMessage( _( 'Antrag konnte nicht gesendet werden.' ), 'text-danger', 'times' )

                :

                    ''

                )

        )

    }

    renderRequestButtons() {

        const missingDocuments = []

        this.state.documentTypes && this.state.documentTypes.forEach( documentType => parseJSON( documentType.otherConfig, {} ).requiredForPersonRequest && ! this.state.personRequest.PersonDocuments.find( e => e.DocumentType.Id === documentType.id ) && missingDocuments.push( documentType ) )

        return (

            <>

                { this.state.step < 3 && <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button> }

                { this.state.step === 1 ?

                    <Button onClick={ () => this.save( PERSON_REQUEST_STATUS_DRAFT, 2 ) } disabled={ this.state.saving }>{ _( 'Weiter' ) }</Button>

                : this.state.step === 2 ?

                    <>

                        <Button variant='secondary' onClick={ () => this.setState( { step: 1 } ) }>{ _( 'Zurück' ) }</Button>

                        <Button onClick={ () => this.send() } disabled={ missingDocuments.length > 0 }>{ _( 'Antrag senden' ) }</Button>

                    </>

                :

                    <Button onClick={ this.props.onHide } disabled={ this.state.saving }>{ _( 'Schließen' ) }</Button>

                }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.state.personRequest !== null } onHide={ this.props.onHide } backdrop='static' size='lg'>

                { this.state.personRequest &&

                    <>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Lizenzerstausstellung' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            { this.state.isNew ? this.renderRequestBody() : this.renderBody() }

                        </Modal.Body>

                        <Modal.Footer>

                            { this.state.isNew ? this.renderRequestButtons() : this.renderButtons() }

                        </Modal.Footer>

                    </>

                }

            </Modal>

        )

    }

}