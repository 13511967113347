import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_DATE, FIELD_TYPE_TEXTFIELD } from '../util/constants'

export const PersonRequest = {

    'Birthdate':        { __label: _( 'Geburtstag'  ), __type: FIELD_TYPE_DATE                                       },
    'BirthName':        { __label: _( 'Mädchenname' ), __type: FIELD_TYPE_TEXTFIELD                                  },
    'FirstName':        { __label: _( 'Vorname'     ), __type: FIELD_TYPE_TEXTFIELD                                  },
    'Gender':           { __label: _( 'Geschlecht'  ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'genders'      },
    'LastName':         { __label: _( 'Nachname'    ), __type: FIELD_TYPE_TEXTFIELD                                  },
    'TargetLeagueType': { __label: _( 'Liga'        ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'leaguesTypes' },

}