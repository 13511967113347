// REACT_APP_MYFEDERATION_CUSTOMER=deb npm start
// REACT_APP_MYFEDERATION_CUSTOMER=deb npm run build

const customers = {

    // DEB
    deb:

    {

        baseDataUrl:                                {

            'deb-myfeddev.morawa.digital':          'https://backend-deb-myfeddev.morawa.digital/',

        },
        devBaseDataUrl:                             'https://backend-deb-myfeddev.morawa.digital/',
        logoImageUrl:                               '/customer/deb/logo.png',

    },

    // ÖEHV
    oeehv:

    {

        baseDataUrl:                                {

            'oeehv-myfeddev.morawa.digital':        'https://backend-oeehv-myfeddev.morawa.digital/',

        },
        devBaseDataUrl:                             'https://backend-oeehv-myfeddev.morawa.digital/',
        logoImageUrl:                               '/customer/oeehv/logo.png',

    },

}

const defaultCustomerKey = 'deb'
  let customerKey        = null

export const getCustomer = () => {

    return customers[ getCustomerKey() ]

}

export const getCustomerKey = () => {

    if ( customerKey ) {

        return customerKey

    }

    return customerKey = process.env.REACT_APP_MYFEDERATION_CUSTOMER || defaultCustomerKey

}