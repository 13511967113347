import { connect } from 'react-redux'
import { setUiValue } from '../../store/actions'
import Requests from '../../components/pages/Requests'
import { PLAYER_LICENSE_REQUEST_STATUS_PENDING, REQUEST_TYPE_PLAYER_LICENSE } from '../../util/constants'

const mapStateToProps = state => ( {

    context:      state.ui.context,
    loading:      state.app.loading,
    statusFilter: state.ui.statusFilterRequests || PLAYER_LICENSE_REQUEST_STATUS_PENDING,
    textFilter:   state.ui.textFilterRequests   || '',
    typeFilter:   state.ui.typeFilterRequests   || REQUEST_TYPE_PLAYER_LICENSE,

} )

const mapDispatchToProps = dispatch => ( {

    onUiValueChange: ( key, value ) => dispatch( setUiValue( key, value ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( Requests )