import { ajx, translate as _, parseJSON } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import { getFieldDefinition } from '../../fieldDefinition'
import { getBooleanValue, getDateValue, getPlayerLicenseStatuses, getSelectValue, getStringValue } from '../../util'
import Field from '../controls/Field'
import { FIELD_TYPE_AUTOCOMPLETE } from '../../util/constants'

export default class PlayerLicense extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            fieldDefinition: null,
            saving:          false,
            playerLicense:   this.props.playerLicense,
            validated:       false,
            valueOptions:    { playerLicenseStatuses: getPlayerLicenseStatuses() },

        }

    }

    componentDidUpdate() {

        if ( ( ! this.props.playerLicense && this.state.playerLicense ) || ( this.props.playerLicense && ! this.state.playerLicense ) ) {

            this.setState( { playerLicense: this.props.playerLicense } )

        }

    }

    save( e ) {

        e.preventDefault()

        if ( ! e.currentTarget.checkValidity() ) {

            this.setState( { validated: true } )

            return

        }

        this.setState( { saving: true, validated: true }, () => {

            const data = {

                clubId:                       getSelectValue( this.state.playerLicense.Club                          ),
                documentArrival:              getDateValue( this.state.playerLicense.DocumentArrival                 ),
                developmentLicense:           getBooleanValue( this.state.playerLicense.DevelopmentLicense           ),
                fromDate:                     getDateValue( this.state.playerLicense.FromDate                        ),
                id:                           this.state.playerLicense.Id,
                licenseExtensionSecondLeague: getBooleanValue( this.state.playerLicense.LicenseExtensionSecondLeague ),
                licensePrintDate:             getDateValue( this.state.playerLicense.LicensePrintDate                ),
                licenseReturnDate:            getDateValue( this.state.playerLicense.LicenseReturnDate               ),
                note:                         getStringValue( this.state.playerLicense.Note                          ),
                playerId:                     this.state.playerLicense.PlayerId,
                playerLicenseTypeId:          getSelectValue( this.state.playerLicense.PlayerLicenseType             ),
                playoffClubId:                getSelectValue( this.state.playerLicense.PlayoffClub                   ),
                release:                      getBooleanValue( this.state.playerLicense.Release                      ),
                restrictedLeagueId:           getSelectValue( this.state.playerLicense.RestrictedLeague              ),
                toDate:                       getDateValue( this.state.playerLicense.ToDate                          ),

            }

            const success = e => {

                this.props.onUpdate( e )
                this.props.onHide()

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/PlayerLicense/AddOrEdit',

                data, success,

            } )

        } )

    }

    selectPlayerLicenseType( PlayerLicenseType ) {

        this.setState( { fieldDefinition: null, playerLicense: { ...this.state.playerLicense, PlayerLicenseType } }, () => {

            const playerLicenseType = this.props.playerLicenseTypesComplete.find( e => e.Id === PlayerLicenseType.value )
            const licenseConfig     = parseJSON( playerLicenseType.LicenseConfig )

            getFieldDefinition( {

                objectName: 'PlayerLicense',
                overwrite:  licenseConfig && licenseConfig.FieldDefinition,
                success:    fieldDefinition => this.setState( { fieldDefinition } ),

            } )

        } )

    }

    render() {

        const valueOptions = { ...( this.props.valueOptions || {} ), ...this.state.valueOptions }

        return (

            <Modal show={ this.state.playerLicense !== null } onHide={ this.props.onHide } backdrop='static'>

                <Form onSubmit={ e => this.save( e ) } noValidate validated={ this.state.validated }>

                    <fieldset disabled={ this.state.saving }>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Lizenz' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            { this.state.playerLicense &&

                                <>

                                    <Field
                                        field={ { object: 'PlayerLicense', property: 'PlayerLicenseType', __label: _( 'Lizenz' ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'playerLicenseTypes' } }
                                        onChange={ ( e, f ) => this.selectPlayerLicenseType( f ) }
                                        value={ this.state.playerLicense.PlayerLicenseType }
                                        valueOptions={ valueOptions }
                                    />

                                    { this.state.playerLicense.PlayerLicenseType &&

                                        <FieldSet
                                            disabled={ this.state.saving }
                                            fieldDefinition={ this.state.fieldDefinition }
                                            onChange={ ( e, f ) => this.setState( { playerLicense: { ...this.state.playerLicense, [ e ]: f } } ) }
                                            valueOptions={ valueOptions }
                                            values={ this.state.playerLicense }
                                        />

                                    }

                                </>

                            }

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                            <Button type='submit'>{ _( 'Speichern' ) }</Button>

                        </Modal.Footer>

                    </fieldset>

                </Form>

            </Modal>

        )

    }

}