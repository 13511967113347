import React from 'react'
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap'
import { Icon, ajx, translate as _ } from '@morawadigital/skynet-framework'
import { deleteItem, findTextInObject, getFullName, getPersonRequestStatuses, getPlayerLicenseRequestStatuses, getTransferRequestStatuses, updateItem } from '../../util'
import { REQUEST_TYPE_PERSON, REQUEST_TYPE_PLAYER_LICENSE, REQUEST_TYPE_TRANSFER, TRANSFER_REQUEST_STATUS_ACCEPTED, TRANSFER_REQUEST_STATUS_PENDING } from '../../util/constants'
import PlayerLicenseRequestItem from '../items/PlayerLicenseRequest'
import PersonRequestItem from '../items/PersonRequest'
import TransferRequestItem from '../items/TransferRequest'
import PersonRequestModal from '../modals/PersonRequest'
import TransferRequestModal from '../modals/TransferRequest'

export default class PlayerLicenseRequests extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            activePersonRequest:                null,
            activeRequestablePlayerLicenseType: null,
            items:                              null,
            requestablePlayerLicenseTypes:      [],
            transferRequestOpen:                false,

        }

    }

    componentDidMount() {

        this.loadItems()

        this.props.context && this.props.context.club && this.loadRequestablePlayerLicenseTypes()

    }

    canIssue() {

        return this.props.context && this.props.context.federation

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'clubName', 'personName' ] ) ) {

            return false

        }

        return true

    }

    convertItem( item ) {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                {

                    ...item,

                    clubName:   item.Club ? item.Club.Name : '',
                    personName: getFullName( item, true ),

                }

            : this.props.typeFilter === REQUEST_TYPE_PLAYER_LICENSE ?

                {

                    ...item,

                    clubName:              item.Club              ? item.Club.Name                          : '',
                    personName:            item.Player            ? getFullName( item.Player.Person, true ) : '',
                    playerLicenseTypeName: item.PlayerLicenseType ? item.PlayerLicenseType.Name             : '',

                }

            :

                {

                    ...item,

                    clubName:   item.Club   ? item.Club.Name                          : '',
                    personName: item.Player ? getFullName( item.Player.Person, true ) : '',

                }

        )

    }

    convertItems( items ) {

        return items.map( e => this.convertItem( e ) )

    }

    createPersonRequest() {

        return {

            Birthdate:        null,
            BirthName:        '',
            FirstName:        '',
            Gender:           null,
            isNew:            true,
            LastName:         '',
            PersonDocuments:  [],
            TargetLeagueType: null,

        }

    }

    getItemComponent() {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                PersonRequestItem

            : this.props.typeFilter === REQUEST_TYPE_PLAYER_LICENSE ?

                PlayerLicenseRequestItem

            :

                TransferRequestItem

        )

    }

    getDataParams() {

        const params = { statusId: this.props.statusFilter }

        if ( this.props.context ) {

            if ( this.props.context.federation ) { params.fedId  = this.props.context.federation.id }
            if ( this.props.context.club       ) { params.clubId = this.props.context.club.id       }

        }

        return params

    }

    getDataUrl() {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                'api/PersonRequest/Get'

            : this.props.typeFilter === REQUEST_TYPE_PLAYER_LICENSE ?

                'api/PlayerLicenseRequest/Get'

            :

                'api/TransferRequest/Get'

        )

    }

    getItemProps() {

        const props = {

            onUpdate:    e => this.updateItem( this.props.typeFilter, e ),
            respondable: this.props.context && this.props.context.federation,
            showPerson:  true,

        }

        if ( this.props.typeFilter === REQUEST_TYPE_PERSON ) {

            props.onClick = e => this.setState( { activePersonRequest: e } )

        }

        return props

    }

    getStatusFilterOptions() {

        return (

            this.props.typeFilter === REQUEST_TYPE_PERSON ?

                getPersonRequestStatuses()

            : this.props.typeFilter === REQUEST_TYPE_PLAYER_LICENSE ?

                getPlayerLicenseRequestStatuses()

            :

                getTransferRequestStatuses()

        )

    }

    handleStatusFilterChange( e ) {

        this.props.onUiValueChange( 'statusFilterRequests', Number( e.target.value ) )

        setTimeout( () => this.loadItems(), 0 )

    }

    handleTypeFilterChange( e ) {

        this.props.onUiValueChange( 'typeFilterRequests', e.target.value )

        setTimeout( () => this.loadItems(), 0 )

    }

    loadItems() {

        ajx( {

            data:          this.getDataParams(),
            options:       { method: 'GET' },
            success:       e => this.setState( { items: this.convertItems( e ) } ),
            timeout:       400,
            toggleLoading: true,
            url:           this.getDataUrl(),

        } )

    }

    loadRequestablePlayerLicenseTypes() {

        ajx( {

            data:    { clubId: this.props.context && this.props.context.club && this.props.context.club.id },
            options: { method: 'GET' },
            success: requestablePlayerLicenseTypes => this.setState( { requestablePlayerLicenseTypes } ),
            url:     'api/PlayerLicenseType/Get',

        } )

    }

    updateItem( type, item ) {

        if ( this.props.context && this.props.context.federation && item.Status === TRANSFER_REQUEST_STATUS_PENDING && [ REQUEST_TYPE_PLAYER_LICENSE, REQUEST_TYPE_TRANSFER ].includes( type ) ) {

            ajx( {

                data:    { id: item.Id, status: TRANSFER_REQUEST_STATUS_ACCEPTED },
                single:  true,
                success: e => this.updateItem( type, e ),
                url:     'api/TransferRequest/Respond',

            } )

        } else if ( type === this.props.typeFilter ) {

            item.Status === this.props.statusFilter ?

                this.setState( { items: updateItem( this.state.items, this.convertItem( item ) ) } )

            :

                this.setState( { items: deleteItem( this.state.items, item ) } )

        }

    }

    renderFilters() {

        return (

            <Form className='sticky-filters'>

                <fieldset disabled={ this.props.loading }>

                    <Row className='gy-1'>

                        <Col xs='12' md='4'>

                            <Form.Select className='py-2' onChange={ e => this.handleTypeFilterChange( e ) } value={ this.props.typeFilter }>

                                <option value={ REQUEST_TYPE_PLAYER_LICENSE }>{ _( 'Spielerlizenzen' ) }</option>
                                <option value={ REQUEST_TYPE_PERSON         }>{ _( 'Lizenzerstausstellung' ) }</option>
                                <option value={ REQUEST_TYPE_TRANSFER       }>{ _( 'Transfers / Andere Lizenzen' ) }</option>

                            </Form.Select>

                        </Col>

                        <Col>

                            <Form.Select className='py-2' onChange={ e => this.handleStatusFilterChange( e ) } value={ this.props.statusFilter }>

                                { this.getStatusFilterOptions().map( ( e, i ) =>

                                    <option value={ e.value } key={ i }>{ e.label }</option>

                                ) }

                            </Form.Select>

                        </Col>

                        <Col>

                            <Form.Control
                                onChange={ e => this.props.onUiValueChange( 'textFilterRequests', e.target.value ) }
                                placeholder={ _( 'Filtern...' ) }
                                value={ this.props.textFilter }
                            />

                        </Col>

                    </Row>

                </fieldset>

            </Form>

        )

    }

    renderSubheaderBlock() {

        return (

            this.props.context && this.props.context.club ?

                <Dropdown>

                    <Dropdown.Toggle>

                        { _( 'Neuer Antrag' ) }

                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <Dropdown.Item as='button' onClick={ () => this.setState( { activePersonRequest: this.createPersonRequest() } ) }>{ _( 'Lizenzerstausstellung' ) }</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={ () => this.setState( { activeRequestablePlayerLicenseType: null, transferRequestOpen: true } ) }>{ _( 'Transfer' ) }</Dropdown.Item>

                        <Dropdown.Divider />

                        { this.state.requestablePlayerLicenseTypes.map( ( e, i ) =>

                            <Dropdown.Item as='button' onClick={ () => this.setState( { activeRequestablePlayerLicenseType: e, transferRequestOpen: true } ) } key={ i }>{ e.Name }</Dropdown.Item>

                        ) }

                    </Dropdown.Menu>

                </Dropdown>

            : this.canIssue() ?

                <Button onClick={ () => this.setState( { activeRequestablePlayerLicenseType: null, transferRequestOpen: true } ) }>{ _( 'Spieler:in transferieren' ) }</Button>

            : ''

        )

    }

    render() {

        const ItemComponent = this.getItemComponent()
        const filteredItems = this.state.items ? this.state.items.filter( e => this.checkItem( e ) ) : null

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='id-card' className='subheader-icon' /> { _( 'Anträge' ) }

                    </h1>

                    <div className='subheader-block'>

                        { this.renderSubheaderBlock() }

                    </div>

                </div>

                { this.renderFilters() }

                { this.props.loading ?

                    Array.from( { length: 3 } ).map( ( e, i ) => <ItemComponent key={ i } index={ i } /> )

                : filteredItems && ! filteredItems.length ?

                    <div className='my-3 text-center'>{ _( 'Keine Einträge gefunden.' ) }</div>

                : filteredItems ?

                    <>

                        <div className='text-center text-muted mt-2 mb-3'>{ filteredItems.length + ' ' + ( filteredItems.length === 1 ? _( 'Eintrag' ) : _( 'Einträge' ) ) }</div>

                        { filteredItems.map( ( item, i ) => <ItemComponent key={ i } item={ item } { ...this.getItemProps() } /> ) }

                    </>

                :

                    ''

                }

                <PersonRequestModal
                    context={ this.props.context }
                    onHide={ () => this.setState( { activePersonRequest: null } ) }
                    onUpdate={ e => this.updateItem( REQUEST_TYPE_PERSON, e ) }
                    personRequest={ this.state.activePersonRequest }
                    respondable={ this.props.context && this.props.context.federation }
                />

                <TransferRequestModal
                    context={ this.props.context }
                    onHide={ () => this.setState( { activeRequestablePlayerLicenseType: null, transferRequestOpen: false } ) }
                    onUpdate={ e => this.updateItem( REQUEST_TYPE_TRANSFER, e ) }
                    playerLicenseType={ this.state.activeRequestablePlayerLicenseType }
                    show={ this.state.transferRequestOpen }
                />

            </>

        )

    }

}