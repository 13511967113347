import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_FLEX_DATE, FIELD_TYPE_RANGE } from './constants'

const fields = [

    { group: 'club',   name: 'name',      label: _( 'Name'          ), keyWord: _( 'verein'     ), param: 'name'                                                             },

    { group: 'person', name: 'passNumber', label: _( 'Passnummer'   ), keyWord: _( 'passnr'     ), param: 'passNr'                                                           },
    { group: 'person', name: 'lastName',   label: _( 'Nachname'     ), keyWord: _( 'nachname'   ), param: 'lastName'                                                         },
    { group: 'person', name: 'firstName',  label: _( 'Vorname'      ), keyWord: _( 'vorname'    ), param: 'firstName'                                                        },
    { group: 'person', name: 'birthdate',  label: _( 'Geburtsdatum' ), keyWord: _( 'geboren'    ), param: 'birthdate',  type: FIELD_TYPE_FLEX_DATE                           },
    { group: 'person', name: 'yob',        label: _( 'Jahrgang'     ), keyWord: _( 'jahrgang'   ), param: 'yearFromTo', type: FIELD_TYPE_RANGE                               },
    { group: 'person', name: 'gender',     label: _( 'Geschlecht'   ), keyWord: _( 'geschlecht' ), param: 'gender',     type: FIELD_TYPE_AUTOCOMPLETE, optionsKey: 'genders' },

]

export const getSearchFields = () => fields