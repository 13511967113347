import React from 'react'
import { Button, Card, Col, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap'
import { ConfirmationModal, Icon, translate as _, ajx, cancelEvent, getDataUrl, getReadableFileSize, parseJSON } from '@morawadigital/skynet-framework'
import { getLabel } from '../../fieldDefinition'
import { getFileExtension, getFileIcon, getImageFileExtensions } from '../../util/file'

export default class Document extends React.Component {

    #imageFileExtensions = getImageFileExtensions()
    #supportsDnD         = 'draggable' in document.createElement( 'span' )

    constructor( props ) {

        super( props )

        this.state = {

            deleteModalOpen: false,
            deleting:        false,
            dnDAreaEntered:  false,
            error:           null,
            progress:        0,
            selectedFile:    null,
            uploading:       false,

        }

    }

    deleteFile() {

        this.setState( { deleting: true }, () =>

            ajx( {

                complete: () => this.setState( { deleting: false } ),
                data:     { docId: this.props.document.Id },
                error:    () => this.setError( _( 'Fehler beim Löschen' ) ),
                options:  { method: 'GET' },
                timeout:  1000,
                url:      'api/' + this.props.objectName + '/DeleteFile',
                success:  () => this.props.onDelete( this.props.document ),

            } )

        )

    }

    downloadFile() {

        window.location.href = getDataUrl( 'api/' + this.props.objectName + '/DownloadFile' ) + '?docId=' + this.props.document.Id + '&token=' + this.props.token

    }

    getUploadConfig() {

        return parseJSON( this.props.documentType.uploadConfig, {} )

    }

    getUploadRestrictions( uploadConfig = this.getUploadConfig() ) {

        const uploadRestrictions = []

        if ( uploadConfig.allowedFileTypes && uploadConfig.allowedFileTypes.length ) {

            uploadRestrictions.push( uploadConfig.allowedFileTypes.map( e => e.toUpperCase() ).join( ', ' ) )

        }

        if ( uploadConfig.maxFileSize ) {

            uploadRestrictions.push( '≤ ' + getReadableFileSize( uploadConfig.maxFileSize ) )

        }

        if ( uploadConfig.maxWidth && uploadConfig.maxHeight ) {

            uploadRestrictions.push( '≤ ' + uploadConfig.maxWidth + 'x' + uploadConfig.maxHeight + 'px' )

        } else {

            if ( uploadConfig.maxWidth  ) { uploadRestrictions.push( uploadConfig.maxWidth  + 'px ' + _( 'breit' ) ) }
            if ( uploadConfig.maxHeight ) { uploadRestrictions.push( uploadConfig.maxHeight + 'px ' + _( 'hoch'  ) ) }

        }

        return uploadRestrictions

    }

    handleDndDrop( e ) {

        cancelEvent( e )

        this.setState( { dnDAreaEntered: false }, () => this.setFiles( e.dataTransfer.files ) )

    }

    handleDnDEnter( e ) {

        cancelEvent( e )

        ! this.state.dnDAreaEntered && this.setState( { dnDAreaEntered: true } )

    }

    handleDnDLeave( e ) {

        cancelEvent( e )

        this.state.dnDAreaEntered && this.setState( { dnDAreaEntered: false } )

    }

    selectFiles() {

        const uploadConfig = this.getUploadConfig()
        const input        = document.createElement( 'input' )

        input.type     = 'file'
        input.accept   = uploadConfig.allowedFileTypes ? uploadConfig.allowedFileTypes.map( e => '.' + e ).join( ',' ) : ''
        input.multiple = false

        input.addEventListener( 'change', e => this.setFiles( e.target.files ) )
        input.click()

    }

    setError( error ) {

        this.setState( { error }, () => setTimeout( () => this.setState( { error: null } ), 3000 ) )

    }

    setFiles( files ) {

        if ( this.props.disabled || ! files || ! files.length ) {

            return

        }

        const file         = files[ 0 ]
        const uploadConfig = this.getUploadConfig()

        if ( uploadConfig.allowedFileTypes && ! uploadConfig.allowedFileTypes.includes( getFileExtension( file.name ) ) ) {

            return this.setError( _( 'Ungültiger Dateityp' ) )

        }

        if ( uploadConfig.maxFileSize && file.size > uploadConfig.maxFileSize ) {

            return this.setError( _( 'Datei zu groß' ) )

        }

        if ( uploadConfig.isImage && ( uploadConfig.maxWidth || uploadConfig.maxHeight ) ) {

            const img = new Image()

            img.onload = () => {

                if ( uploadConfig.maxWidth  && img.width  > uploadConfig.maxWidth  ) { return this.setError( _( 'Bild zu breit' ) ) }
                if ( uploadConfig.maxHeight && img.height > uploadConfig.maxHeight ) { return this.setError( _( 'Bild zu hoch'  ) ) }

                this.uploadFile( file )

            }

            img.src = URL.createObjectURL( file )

        } else {

            this.uploadFile( file )

        }

    }

    uploadFile( file ) {

        this.setState( { uploading: true, progress: 10 }, () => {

            const progressTimer = setInterval( () => this.setState( { progress: this.state.progress + ( ( 100 - this.state.progress ) / 3 ) } ), 300 )

            ajx( {

                complete:  () => window.clearInterval( progressTimer ) || this.setState( { uploading: false, progress: 0 } ),
                data:      { file },
                error:     () => this.setError( _( 'Fehler beim Hochladen' ) ),
                single:    true,
                success:   e => this.props.onChange( e ),
                timeout:   1000,
                url:       'api/' + this.props.objectName + '/UploadFile',
                urlParams: { ...this.props.uploadParams, docTypeId: this.props.documentType.id },
                urlToken:  true,

            } )

        } )

    }

    renderDocument() {

        const uploadConfig       = this.getUploadConfig()
        const uploadRestrictions = this.getUploadRestrictions( uploadConfig )

        return (

            <>

                <div className='document-preview'>

                    { uploadConfig.isImage || this.#imageFileExtensions.includes( getFileExtension( this.props.document.Url ) ) ?

                        <img src={ this.props.document.Url } alt={ this.props.document.Path } />

                    :

                        <div className='document-preview-icon'>

                            <Icon icon={ getFileIcon( getFileExtension( this.props.document.Url ) ) } />

                        </div>

                    }

                </div>

                <a href={ this.props.document.Url } target='_blank' rel='noopener noreferrer' className='document-overlay'><span></span></a>

                <div className='document-buttons'>

                    <Row>

                        <Col>

                            { ! this.props.disabled && <OverlayTrigger overlay={ <Tooltip>{ <>{ _( 'Neue Datei hochladen' ) }{ uploadRestrictions.length > 0 && <><br />({ uploadRestrictions.join( ' | ' ) })</> }</> }</Tooltip> }>

                                <Button variant='document-action' onClick={ () => this.selectFiles() }><Icon icon='upload' /></Button>

                            </OverlayTrigger> }

                            <OverlayTrigger overlay={ <Tooltip>{ _( 'Datei herunterladen' ) }</Tooltip> }>

                                <Button variant='document-action' onClick={ () => this.downloadFile() } className='ms-1'><Icon icon='download' /></Button>

                            </OverlayTrigger>

                        </Col>

                        { ! this.props.disabled && <Col className='text-end'>

                            <OverlayTrigger overlay={ <Tooltip>{ _( 'Datei löschen' ) }</Tooltip> }>

                                <Button variant='document-action' onClick={ () => this.setState( { deleteModalOpen: true } ) }><Icon icon='trash' /></Button>

                            </OverlayTrigger>

                        </Col> }

                    </Row>

                </div>

            </>

        )

    }

    renderDeleting() {

        return (

            <div className='text-center'>

                <p><Icon icon='trash' className='fs-1' /></p>

                <p>{ _( 'Datei wird gelöscht...' ) }</p>

            </div>

        )

    }

    renderError() {

        return (

            <div className='text-center'>

                <p><Icon icon='exclamation-triangle' className='fs-1' /></p>

                <p>{ this.state.error }</p>

            </div>

        )

    }

    renderUpload() {

        if ( this.props.disabled ) {

            return

        }

        const uploadRestrictions = this.getUploadRestrictions()

        return (

            <div className='text-center'>

                <p>

                    <Button variant='outline-primary' size='sm' onClick={ () => this.selectFiles() }>{ _( 'Datei auswählen' ) }</Button>

                </p>

                { this.#supportsDnD && <p>{ _( 'oder hierherziehen' ) }</p> }

                { uploadRestrictions.length > 0 &&

                    <>

                        <hr />

                        <p>

                            { uploadRestrictions.map( ( e, i ) =>

                                <span className='document-upload-restriction' key={ i }>

                                    { e }

                                    { i + 1 < uploadRestrictions.length && <span className='text-muted mx-1'>|</span> }

                                </span>

                            ) }

                        </p>

                    </>

                }

            </div>

        )

    }

    renderUploading() {

        return (

            <div className='text-center'>

                <p>{ _( 'Datei wird hochgeladen...' ) }</p>

                <ProgressBar animated striped now={ this.state.progress } />

            </div>

        )

    }

    render() {

        const bg   = this.state.dnDAreaEntered ? 'success' : this.state.error ? 'danger' : this.state.deleting ? 'warning' : null
        const text = this.state.dnDAreaEntered || this.state.error || this.state.deleting ? 'white' : null

        return (

            <>

                <Card
                    bg=         { bg                            }
                    className=  { 'document'                    }
                    onDrag=     { cancelEvent                   }
                    onDragEnd=  { e => this.handleDnDLeave( e ) }
                    onDragEnter={ e => this.handleDnDEnter( e ) }
                    onDragLeave={ e => this.handleDnDLeave( e ) }
                    onDragOver= { e => this.handleDnDEnter( e ) }
                    onDragStart={ cancelEvent                   }
                    onDrop=     { e => this.handleDndDrop( e )  }
                    text=       { text                          }
                >

                    <Card.Header>

                        { getLabel( this.props.documentType ) }

                        { parseJSON( this.props.documentType.otherConfig, {} )[ this.props.requiredKey ] && <OverlayTrigger overlay={ <Tooltip>{ _( 'Diese Datei ist verpflichtend.' ) }</Tooltip> }><span className='text-info ms-1'><Icon icon='asterisk' /></span></OverlayTrigger> }

                    </Card.Header>

                    <Card.Body>

                        { this.state.deleting ?

                            this.renderDeleting()

                        : this.state.uploading ?

                            this.renderUploading()

                        : this.state.error ?

                            this.renderError()

                        : this.props.document ?

                            this.renderDocument()

                        :

                            this.renderUpload()

                        }

                    </Card.Body>

                </Card>

                <ConfirmationModal
                    onConfirm={ () => this.setState( { deleteModalOpen: false }, () => this.deleteFile() ) }
                    onHide={ () => this.setState( { deleteModalOpen: false } ) }
                    show={ this.state.deleteModalOpen }
                    text={ _( 'Möchten Sie diese Datei wirklich löschen?' ) }
                    title={ _( 'Datei löschen?' ) }
                />

            </>

        )

    }

}